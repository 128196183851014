import React, { useState, useEffect } from 'react';
import bowser from 'bowser';
import {
  Paper,
  Typography,
  Alert,
  CircularProgress

} from '@mui/material';
import gateway from "../images/gateway-blue.png";
import Footer from "../ui-components/Footer";
import Modal from 'react-modal'
import '../styles/style.css';

function modalLoading() {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <img src={gateway} alt="gateway" style={{ marginLeft: "0px", width: "200px", height: "200px" }} />
      <Typography variant="h2" style={{ textAlign: "center", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

Welcome to Gateway
      </Typography>
    </div>
  );
}


function SupportMessage() {
  const [browserName, setBrowserName] = useState('');
  useEffect(() => {
    const browser = bowser.getParser(window.navigator.userAgent);
    setBrowserName(browser.getBrowserName());
  }, []);
  return (
    <div style={{ marginBottom: '1rem' }}>
      <Typography variant="body1" style={{ fontSize: "22px", textAlign: "left", fontWeight: 'normal', fontFamily: "ubuntu" }}>
      <div class="container">
      <h1>Browser Not Supported</h1>
        <p>We apologise, but <strong>{browserName}</strong> is not supported, Please use either <strong>Google Chrome</strong> or <strong>Mozilla Firefox</strong> to access Gateway.</p>
        <h2>How to Change Your Default Browser in Windows 10</h2>
        <ol>
            <li>Click the <strong>Start</strong> button and type <strong>Default apps</strong>.</li>
            <li>In the search results, select <strong>Default apps</strong>.</li>
            <li>Under <strong>Web browser</strong>, you can see which browser is set as default.</li>
            <li>Click on the browser and select the browser from the list that opens (e.g., Google Chrome or Mozilla Firefox) to set it as default.</li>
        </ol>
        <h2>How to Change Your Default Browser in Microsoft Teams</h2>
        <ol>
            <li>Open Microsoft Teams.</li>
            <li>Click the three dots at the top right and select <strong>Settings</strong>.</li>
            <li>Go to <strong>Files and links</strong>.</li>
            <li>Beside <strong>Links open preference</strong>, click the box that says <strong>Microsoft Edge</strong>.</li>
            <li>In the dropdown, choose either <strong>Google Chrome</strong> or <strong>Mozilla Firefox</strong> as your desired default browser.</li>
        </ol>
        <p>Please contact Central Finance for support</p>

    </div>
      </Typography>
    </div>
  );
}

function UnderlinedText({ children }) {
  const styles = {
    textUnderline: {
      position: 'relative',
    },
    textUnderlineAfter: {
      content: '',
      position: 'absolute',
      left: 0,
      bottom: -6,
      height: '6px', // Adjust thickness
      width: '100%',
      backgroundColor: '#DE772E',
    },
  };

  return <span style={styles.textUnderline}>{children}<span style={styles.textUnderlineAfter}></span></span>;
}

export function Home() {
  const [showUnsupportedAlert, setShowUnsupportedAlert] = useState(false);
  const [browserName, setBrowserName] = useState('');
  const [showLoadingModal, setShowLoadingModal] = useState(true); // For the loading modal

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.75)'
    },
    content: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '5px',
      outline: 'none',
      padding: '20px',
      marginTop: "50px"
    }
  };

  useEffect(() => {
    const browser = bowser.getParser(window.navigator.userAgent);

    if (browser.getBrowserName() === "Chrome" || browser.getBrowserName() === "Firefox") {
      window.location.href = "https://wincanton-ssc.co.uk";  // Redirect Chrome and Firefox users
    } else {
      setShowUnsupportedAlert(true); // Show alert for other browsers
    }
  }, []);

  useEffect(() => {
    const browser = bowser.getParser(window.navigator.userAgent);
    setBrowserName(browser.getBrowserName());
  }, []);

  useEffect(() => {
    // Loading modal timeout
    const timeoutId = setTimeout(() => {
      setShowLoadingModal(false);
    }, 1000); // Timeout of 3 seconds

    return () => clearTimeout(timeoutId);
  }, []);



  return (
    <>
    <Modal
        isOpen={showLoadingModal}
        onRequestClose={() => {}} // Prevent closing on click outside
        contentLabel="Loading Modal"
        ariaHideApp={false} // Important for accessibility 
        style={customStyles}
      >
         <div style={{ textAlign: 'center', fontFamily: "ubuntu" }}>
            <h1
              style={{ fontSize: "80px" }}
            >
             
             {modalLoading()}
            </h1>
            <CircularProgress size="6rem" />
            <p
              style={{ fontSize: "50px" }}
            >Loading...</p>

          </div>
      </Modal>
            <div className="page-container" style={{
          marginTop: "80px",

        }}>
          {showUnsupportedAlert && (
        <Alert variant="filled" severity="error">
        The Browser you are using isn't supported by Gateway, Please use Chrome or Firefox.
      </Alert>
      )}

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", maxWidth: "1200px" }}>
        <div style={{
          textAlign: "left",
          fontSize: "65px",
          fontFamily: "Ubuntu",
          fontWeight: "bold",
          marginTop: "10px",
          marginBottom: "10px",
          width: "100%",
          maxWidth: "1200px"
        }}>

          <Typography variant="h1" style={{ lineHeight: "2", fontSize: "80px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

            <UnderlinedText> Ooops, we don't support... </UnderlinedText>
           

          </Typography>
          <Typography variant="h1" style={{ fontSize: "80px", textAlign: "left", fontWeight: 'bold', fontFamily: "ubuntu", marginBottom: "10px" }}>

          {browserName}



          </Typography>


        </div>
        <Paper elevation={3} style={{ border: '6px solid #DE772E', borderRadius: '10px', padding: '3rem', textAlign: 'center', marginBottom: '80px',marginTop: '10px', width: "100%", maxWidth: "1100px" }}>
          <SupportMessage />
          <div>

          </div>


        </Paper>

      </div>
      </div>
      <div style={{

        width: '100%',
      }}>
        <Footer />
      </div>


    </>
  );
}
